.contact-info-container {
    max-width: 50%; /* Limit width of the contact info container */
    padding: 40px; /* Padding around the container */
    display: flex; /* Flexbox for aligning illustration and details */
    flex-direction: column; /* Stack illustration above contact details */
    align-items: center; /* Center align the content */
    background-color: #e0f7ff; /* Light blue background */
    border-radius: 20px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .contact-illustration {
    width: 100%; /* Full width for the illustration */
    max-width: 300px; /* Maximum width */
    margin-bottom: 20px; /* Space below the illustration */
  }
  
  .contact-details {
    width: 100%; /* Full width for the contact details */
  }
  
  .contact-item {
    display: flex; /* Flexbox for aligning icon and text */
    margin-bottom: 20px; /* Space below each contact item */
  }
  
  .contact-icon {
    font-size: 24px; /* Large font size for icons */
    color: #1e90ff; /* Bright blue for icons */
    margin-right: 10px; /* Space between icon and text */
  }
  .contact-info a {
    text-decoration: none;
    color: inherit; /* This ensures the link inherits the color from its parent, so it looks like normal text */
}


  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .contact-info-container {
      max-width: 100%; /* Full width on mobile */
      padding: 20px; /* Reduce padding on mobile */
    }
  
    .contact-illustration {
      max-width: 100%; /* Full width for the illustration on mobile */
    }

    .contact-info a {
      text-decoration: none;
      color: inherit; /* This ensures the link inherits the color from its parent, so it looks like normal text */
  }
  }
  