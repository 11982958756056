.projects-container {
    display: flex; /* Flexbox for aligning cards side by side */
    gap: 20px; /* Space between cards */
    justify-content: center; /* Center the cards */
    flex-wrap: wrap; /* Wrap cards to the next line if needed */
  }
  .maina {
    /* Add your styles here */
    color: blue; /* Example: Change text color to blue */
    text-decoration: none; /* Example: Remove the underline */
    font-weight: bold; /* Example: Make the text bold */
    padding: 10px; /* Example: Add padding around the text */
    border: 1px solid #ccc; /* Example: Add a border around the link */
    justify-self: center;
}
  
  .project-card {
    width: 250px; /* Fixed width for each card */
    background-color: #ffffff; /* White background for cards */
    border-radius: 20px; /* Rounded corners */
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    overflow: hidden; /* Hide overflow of the card content */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for hover effect */
    
  }
  
  .project-card:hover {
    transform: translateY(-10px); /* Slightly lift the card on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  }
  
  .project-image {
    width: 100%; /* Full width for the image */
    height: auto; /* Maintain aspect ratio */
    min-height: 60%; /* Ensures a minimum height */
    border-bottom: 1px solid #e0eaff; /* Subtle border below the image */
    border-radius: 20px; /* Rounded corners */
    object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
}


  .project-content {
    padding: 20px; /* Padding inside the card */
  }
  
  .project-title {
    font-size: 18px; /* Font size for the title */
    color: #1f2a47; /* Darker color for the title */
    margin-bottom: 10px; /* Space below the title */
  }
  
  .project-description {
    font-size: 14px; /* Font size for the description */
    color: #666666; /* Gray color for the description */
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
  }
  
  .modal-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .modal-content img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Preserve the aspect ratio */
    border-radius: 20px;
  }
  
  .project-card {
    cursor: pointer; /* Change cursor to pointer to indicate clickability */
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .project-card {
      width: 100%; /* Full width for cards on mobile */
      margin-bottom: 20px; /* Space below each card */
    }
  }
  