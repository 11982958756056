.app-container2 {
    padding: 40px; /* Padding around the whole app */
    background-color: #e9f1fb; /* Light background color */
    display: flex; /* Enable Flexbox layout */
    flex-direction: column; /* Stack items vertically */
    gap: 20px; /* Optional: Add space between the items */
}
  
/* Responsive Design for Mobile */
@media (max-width: 768px) {
    .app-container {
        padding: 20px; /* Reduce padding on mobile */
        /* No need to change flex-direction as it should remain column for vertical stacking */
    }
}
