.contact-form-container {
  display: flex;
  background-color: #e9f1fb;
  width: fit-content;
  margin: 0 auto;
  
}

.form-section {
  flex: 1;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content:end;
  margin-left: 30px;
  padding-bottom: 15px;
}

.form-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  margin-left: 27%;
}

.input-group {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 10px;
}

.input-group label {
  display: inline-block;
  font-size: 14px;
  color: #1f78ff;
  margin-bottom: 5px;
  font-weight: 700;
  margin-top: 15px;
  margin-right: 10px;
  width: 150px; /* Fixed width to align text */
  text-align: right; /* Align text to the right within the label */
  
  
}

.input-group input, 
.input-group textarea {
  width: 100%;
  padding: 20px;
  font-size: 14px;
  color: #9ca3af; /* Light grey color for placeholder text */
  background-color: #f8fafc; /* Light background color */
  border: none;
  border-radius: 15px; /* Rounded corners */
  box-shadow: 
    0 4px 8px rgba(0, 0, 0, 0.1), /* Subtle shadow at the bottom */
    inset 0 -4px 8px rgba(255, 255, 255, 0.8); /* White shadow inset on top */
  outline: none;
  resize: none; /* Prevent resizing */
  scroll-padding-block: 10px;
    
}

.input-group textarea {
  resize: none;
  height: 60px;
}

.send-message-button {
  margin: 0px auto; /* Center the button horizontally */
  display: block; /* Ensure the button behaves as a block element */
  padding: 10px 20px; /* Padding inside the button */
  border: none; /* No border */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size for button text */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  background-color: #F2F2F2; /* Light grey background on hover */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
  border: 1px solid #F2F2F2; /* Add a light grey border on hover */
  color: #0066ff;
  font-weight: 900;
  width: fit-content; /* Ensure the button width fits its content */
  margin-right: 15%;
  
}



.send-message-button .send-icon {
  margin-right: 10px;
}

.image-section {
  flex: 1;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center; /* Center the image horizontally */
  margin-left: 30px;
  padding-bottom: 15px;
}

.image-section img {
  max-width: 300px;
  height: 300px;
  border-radius: 20px;
  margin-bottom: 40px;
  margin: 12px;
}


.contact-info {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.info-item {
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px 20px; /* Padding inside the button */
  border: none; /* No border */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 12px; /* Font size for button text */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  background-color: #F2F2F2; /* Light grey background on hover */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
  border: 1px solid #F2F2F2; /* Add a light grey border on hover */
  color: #0066ff;
  font-weight: 700;
  justify-items: center;
  display: flex; /* Use flexbox to center content */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  
}


.info-item .icon {
  font-size: 20px;
  margin-right: 10px;
  color: #1f78ff;
}



@media (max-width: 768px) {
  .contact-form-container {
  
    display: flex; /* Flexbox to arrange text and image */
  justify-content: space-between; /* Space between text and image */
  align-items: center; /* Align items vertically */
  background-color: #e9f1fb; /* Light background color */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  height: auto; /* Ensure height is determined by content */
  overflow: hidden; /* Ensure content does not overflow the container */
  width: 100%; /* Ensure the hero section takes up full width */
  box-sizing: border-box; /* Include padding in element's width */
  margin: 0 auto; /* Center the hero section */ 
  }
  .form-section, .image-section {
      max-width: 100%;
      
  }
  .image-section img {
      margin-bottom: 50px;
  }
  .info-item {
    font-size: 12px;
  }
  .form-section {
    flex: 1;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    margin-left: -12px;
    padding-bottom: 5px;
  }

  .form-section h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
    margin: 0 auto;
  }
  
  .input-group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 10px;
  }
  
  .input-group label {
    display: inline-block;
    font-size: 14px;
    color: #1f78ff;
    margin-bottom: 5px;
    font-weight: 700;
    margin-top: 15px;
    margin-right: 10px;
    width: 100px; /* Fixed width to align text */
    text-align: right; /* Align text to the right within the label */
    
    
  }


.send-message-button {
  margin: 0px auto; /* Center the button horizontally */
  display: block; /* Ensure the button behaves as a block element */
  padding: 10px 20px; /* Padding inside the button */
  border: none; /* No border */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 14px; /* Font size for button text */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  background-color: #F2F2F2; /* Light grey background on hover */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
  border: 1px solid #F2F2F2; /* Add a light grey border on hover */
  color: #0066ff;
  font-weight: 700;
  width: fit-content; /* Ensure the button width fits its content */
  margin-right: 15%;
  margin-top: 30px;
  
}

.info-item {
  margin: 5px; /* Center the button horizontally */
  display: block; /* Ensure the button behaves as a block element */
  padding: 10px 20px; /* Padding inside the button */
  border: none; /* No border */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 12px; /* Font size for button text */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  background-color: #F2F2F2; /* Light grey background on hover */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
  border: 1px solid #F2F2F2; /* Add a light grey border on hover */
  color: #0066ff;
  font-weight: 600;
  width: fit-content; /* Ensure the button width fits its content */
  justify-items: center;
  display: flex; /* Use flexbox to center content */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  flex-wrap: wrap;
  padding-bottom: 5px;
  
  
}

.image-section img {
  max-width: 300px;
  border-radius: 20px;
  padding: 20px;
  margin: 0px;
}

.image-section {
  flex: 1;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  margin-top: 30px;
  margin-left: 0px;
}

.contact-info {
  display: flex;
  flex-direction: column; /* Stack the items vertically by default (for mobile) */
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
}
