.hero {
  display: flex; /* Flexbox to arrange text and image */
  justify-content: space-between; /* Space between text and image */
  align-items: center; /* Align items vertically */
  background-color: #e9f1fb; /* Light background color */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  height: auto; /* Ensure height is determined by content */
  overflow: hidden; /* Ensure content does not overflow the container */
  width: 100%; /* Ensure the hero section takes up full width */
  box-sizing: border-box; /* Include padding in element's width */
  margin: 0 auto; /* Center the hero section */
 
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Optional: Ensure the div has height to center content within */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  align-self: center;
  margin: 0 auto; /* Ensure it doesn't overflow the container */
  box-sizing: border-box; /* Include padding in element's width */
}

.hero2 {
  display: flex; /* Flexbox to arrange text and image */
  justify-content: center; /* Space between text and image */
  align-items: center; /* Align items vertically */
  background-color: #e9f1fb; /* Light background color */
  border-radius: 20px; /* Rounded corners */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  height: auto; /* Ensure height is determined by content */
  overflow: hidden; /* Ensure content does not overflow the container */
  box-shadow: 
    inset -12px 0 24px rgba(0, 0, 0, 0.06),  /* Left inner shadow (80% lighter) */
    inset 0 -8px 16px rgba(0, 0, 0, 0.06),  /* Top inner shadow (80% lighter) */
    inset 12px 0 24px rgba(255, 255, 255, 0.14), /* Right inner white shadow (80% lighter) */
    inset 0 12px 24px rgba(255, 255, 255, 0.14); /* Bottom inner white shadow (80% lighter) */
  width: 100%; /* Ensure the hero section takes up full width */
  box-sizing: border-box; /* Include padding in element's width */
  margin: 0 10%; /* Center the hero section */
}

.hero-text {
  max-width: 50%; /* Limit width of text section */
  flex: 1; /* Allow the text section to take available space */
  display: flex; /* Enable Flexbox for the content inside */
  justify-content: center; /* Center content horizontally */
  text-align: center; /* Center text within the content */
  flex-direction: column; /* Ensure vertical stacking of text elements */
  padding: 20px; /* Add padding of 20 pixels inside */
  box-sizing: border-box; /* Include padding in element's width */
}


.hero h1 {
  display: inline-block; /* Allows each character to be treated as a block */
  background: linear-gradient(to bottom, #449cf6, #57baf7); /* Gradient from top to bottom */
  -webkit-background-clip: text; /* Clip the background to the text */
  -webkit-text-fill-color: transparent; /* Make the text transparent so the gradient shows through */
  background-clip: text; /* Ensure the gradient is clipped to the text */
 
}




.hero p {
  font-size: 14px; /* Font size for paragraph */
  margin: 20px 0; /* Margin around paragraph */
}

.explore-button {
  padding: 10px 20px; /* Padding inside button */
  border: none; /* No border */
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  width: fit-content;
  align-self: center;
  color: #000000; /* Change text color to black on hover */
  background-color: #F2F2F2; /* Light grey background on hover */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
  border: 1px solid #F2F2F2; /* Add a light grey border on hover */
  font-size: 13px; /* Slightly increase text size on hover */
  line-height: 1.2; /* Maintain the same line height */
}

.explore-button:hover {
  background-color: #F2F2F2; /* Light blue background on hover */
  transform: scale(1.05); /* Slightly increase size on hover */
}

.hero-image {
  position: relative; /* Create a positioning context for the images */
  width: fit-content; /* The container should only be as wide as its content */
  flex: 1; /* Allow the image section to take available space */
}

.image-container {
  position: relative; /* Create a positioning context for the images inside */
  width: fit-content; /* The container should only be as wide as its content */
}

.image-one {
  position: absolute; /* Use absolute positioning to overlap the images */
  top: 0;
  left: 0;
  z-index: 1; /* Ensure the first image is beneath the second image */
  width: 100%; /* Set width for the first image */
  height: auto; /* Maintain aspect ratio */
  
}




/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .hero {
    flex-direction: column; /* Stack text and image vertically */
    padding: 20px; /* Reduce padding on smaller screens */
  }

  .hero2 {
    flex-direction: column; /* Stack text and image vertically */
    padding: 20px; /* Reduce padding on smaller screens */
    margin: 0 auto;
  }

  .hero-text {
    max-width: 100%; /* Allow text to take full width */
    text-align: center; /* Center-align text */
  }

  .hero h1 {
    font-size: 28px; /* Slightly smaller headline font */
  }

  .hero p {
    font-size: 16px; /* Slightly smaller paragraph font */
  }

  .explore-button {
    margin-top: 20px; /* Add margin above the button */
  }

  .hero-image img {
    margin-top: 20px; /* Add space above the image */
  }
}
