.skills-container {
    margin-top: 40px; /* Space above the skills section */
    padding: 40px; /* Padding around the section */
    background-color: #e9f1fb; /* Light background */
  }
  
  .skills-container h2 {
    font-size: 28px; /* Font size for Skills heading */
    color: #000; /* Bright blue for heading */
    margin-bottom: 50px; /* Space below heading */
    text-align: center; /* Center the heading */
  }

  .skillsetup{
    display: flex;
    align-items: center; /* Vertically center all items */
  }

  

  
  .skills-list {
    display: flex; /* Flexbox for aligning skills side by side */
    flex-wrap: wrap; /* Wrap skills to the next line if needed */
    justify-content: space-between; /* Space between skill items */
  }
  
  .skill-item {
    width: 48%; /* Width for each skill item */
    margin-bottom: 20px; /* Space below each skill item */
    text-align: end;
  }
  
  
  .skill-name {
    font-size: 14px; /* Font size for skill name */
    margin-bottom: 0px; /* Space below skill name */
    color: #1f2a47; /* Darker color for text */
    color: #1e90ff;
    font-weight: 700;
    flex: 2;
    text-align: end;
    
    
  }
  
  .skill-bar {
    width: 100%; /* Full width for the bar */
    background-color: #e9f1fb; 
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Hide overflow of the inner bar */
    height: 10px;
    flex: 7;
    align-content: center;
    margin-left:20px;
    margin-right: 20px;
    box-shadow: 
    inset -12px 0 24px rgba(0, 0, 0, 0.06),  /* Left inner shadow (80% lighter) */
    inset 0 -8px 16px rgba(0, 0, 0, 0.06),  /* Top inner shadow (80% lighter) */
    inset 12px 0 24px rgba(255, 255, 255, 0.14), /* Right inner white shadow (80% lighter) */
    inset 0 12px 24px rgba(255, 255, 255, 0.14); /* Bottom inner white shadow (80% lighter) */
  }
  
  .skill-level {
    background-color: #1e90ff; /* Bright blue for skill level */
    padding: 5px; /* Padding inside the skill level bar */
    color: #ffffff; /* White text inside the bar */
    text-align: right; /* Align text to the right */
    border-radius: 10px; /* Rounded corners */
    flex: 1;
    
  }
  
  .skillsetup span {
    color: grey;
    font-size:8;
  }
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
   
    .skills-container {
    margin-top: 40px;
    padding: 0px;
    margin: 0% -10px;
}

.skills-container h2 {
    font-size: 28px;
    color: #000;
    margin-bottom: 30px;
    text-align: center;
}

.skills-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.skill-item {
    width: 100%; /* Full width on mobile */
    margin-bottom: 20px;
    text-align: start; /* Align text to start on mobile */
}

.skillsetup {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space out items on the same line */
    text-align: start; /* Align text to start */
}

.skill-name {
    font-size: 14px;
    color: #1e90ff;
    font-weight: 700;
    flex: none; /* Prevent flex from shrinking or growing */
    width: 80px; /* Fixed width to align all names */
    text-align: end; /* Align text to the right within the fixed width */
}

.skill-bar {
    flex: 7; /* Increase flex to allow the bar to take more space */
    background-color: #e9f1fb; 
    border-radius: 10px;
    overflow: hidden;
    height: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 
    inset -12px 0 24px rgba(0, 0, 0, 0.06),
    inset 0 -8px 16px rgba(0, 0, 0, 0.06),
    inset 12px 0 24px rgba(255, 255, 255, 0.14),
    inset 0 12px 24px rgba(255, 255, 255, 0.14);
}

.skill-level {
    background-color: #1e90ff;
    color: #ffffff;
    text-align: right;
    border-radius: 10px;
}

.skillsetup span {
    color: grey;
    font-size: 12px;
    flex: none; /* Prevent shrinking or growing */
    text-align: start; /* Align text to start */
}
  
  
  }
  