.skills-logos-section {
  position: relative; /* To contain the absolutely positioned elements */
  width: 300px; /* Width and height to define the circular area */
  height: 300px;
  margin: auto; /* Center the circular layout */
  margin-top: 10%;
  animation: rotate-group 20s linear infinite; /* Rotate the entire section */
}

.central-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the central logo */
}

.large-logo {
  width: 150px; /* Size of the central logo */
  padding: 20px; /* Padding inside the element */
  border: none; /* No border */
  border-radius: 75px; /* Rounded corners */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  align-self: center;
  background-color: #F2F2F2; /* Light grey background */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow effects */
  box-sizing: border-box; /* Include padding in the element's total size */
}

.small-logo {
  position: absolute;
  width: 40px; /* Size of the small logos */
}

.logo1 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(0, -150px); /* 0 degrees */
}

.logo2 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(127px, -75px) rotate(60deg); /* 60 degrees */
}

.logo3 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(127px, 75px) rotate(120deg); /* 120 degrees */
}

.logo4 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(0, 150px) rotate(180deg); /* 180 degrees */
}

.logo5 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(-127px, 75px) rotate(240deg); /* 240 degrees */
}

.logo6 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(-127px, -75px) rotate(300deg); /* 300 degrees */
}

.imglogo {
  width: 80px; /* Size of the small logos */
  padding: 20px; /* Padding inside the element */
  border: none; /* No border */
  border-radius: 40px; /* Rounded corners */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  align-self: center;
  background-color: #F2F2F2; /* Light grey background */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow effects */
  box-sizing: border-box; /* Include padding in the element's total size */
}

/* Rotate the entire group of logos */
@keyframes rotate-group {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .skills-logos-section {
      width: 200px; /* Adjust size for smaller screens */
      height: 200px;
  }

  .large-logo {
      width: 60px; /* Adjust size for smaller screens */
  }

  .small-logo {
      width: 30px; /* Adjust size for smaller screens */
  }

  .imglogo {
      width: 60px; /* Size of the small logos */
      padding: 10px; /* Padding inside the element */
      border-radius: 30px; /* Rounded corners */
  }

  /* Adjust position of each logo for smaller screens */
  .logo1 {
      transform: translate(-50%, -50%) translate(0, -100px);
  }

  .logo2 {
      transform: translate(-50%, -50%) translate(85px, -50px) rotate(60deg);
  }

  .logo3 {
      transform: translate(-50%, -50%) translate(85px, 50px) rotate(120deg);
  }

  .logo4 {
      transform: translate(-50%, -50%) translate(0, 100px) rotate(180deg);
  }

  .logo5 {
      transform: translate(-50%, -50%) translate(-85px, 50px) rotate(240deg);
  }

  .logo6 {
      transform: translate(-50%, -50%) translate(-85px, -50px) rotate(300deg);
  }
}
