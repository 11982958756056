.banner-container {
    padding: 40px; /* Padding around the banner */
    background-color: #e9f1fb; /* White background color */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    text-align: center; /* Center align text */
  }
  
  .banner-container h1 {
    font-size: 20px; /* Font size for title */
    color: #1f2a47; /* Darker color for title */
    margin-bottom: 10px; /* Space below title */
    font-style: italic;
  }
  
  .banner-container p {
    font-size: 16px; /* Font size for subtitle */
    color: #666666; /* Gray color for subtitle */
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .banner-container {
      padding: 20px; /* Reduce padding on mobile */
    }
  
    .banner-container h1 {
      font-size: 14px; /* Slightly smaller title font size on mobile */
    }
  
    .banner-container p {
      font-size: 14px; /* Slightly smaller subtitle font size on mobile */
    }
  }
  