.app-containergh {
  margin: 0;
    padding-left: 15%; /* Padding around the whole app */
    padding-right: 15%;
    background-color: #e9f1fb; ; /* Light background color */
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .app-container {
      padding: 20px; /* Reduce padding on mobile */
    }
  }
  