.achievements-section {
    max-width: 60%; /* Limit width of the section */
    padding: 60px; /* Padding around the content */
    padding-left:15%;
    color: #1f2a47; /* Darker color for text */
  }
  
  .achievements-section h2 {
    color: #1e90ff; /* Bright blue for the heading */
    margin-bottom: 10px; /* Space below heading */
    text-decoration-line: underline;
    text-transform: uppercase; /* Transform text to uppercase */
    font-size: 18px;
    text-underline-offset: 5px; /* Increase space between text and underline */
  }
  
  .achievements-section p {
    font-size: 26px; /* Larger font size for paragraph */
    margin-bottom: 20px; /* Space below paragraph */
    font-weight:bolder;
  }
  
  .highlight {
    color: #0066ff; /* Blue color for highlighted text */
  }
  
  .achievement-list {
    list-style-type: none; /* Remove bullets from the list */
    padding: 0; /* Remove padding */
  }
  
  .achievement-list li {
    display: flex; /* Flexbox to arrange icon and text */
    align-items: center; /* Align items vertically */
    margin-bottom: 20px; /* Space between achievements */
  }
  
  .achievement-list img {
    width: 40px; /* Set a fixed width for icons */
    height: 40px;
    margin-right: 20px; /* Space between icon and text */
  }
  
  .achievement-list h3 {
    font-size: 18px; /* Font size for numbers */
    color: #1e90ff; /* Bright blue for numbers */
    margin: 0; /* Remove default margin */
  }
  
  .achievement-list p {
    font-size: 16px; /* Font size for description */
    margin: 0; /* Remove default margin */
  }
  .imgstyle {
    padding: 5px 10px; /* Padding inside button */
  border: none; /* No border */
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  width: fit-content;
  align-self: center;
  color: #000000; /* Change text color to black on hover */
  background-color: #F2F2F2; /* Light grey background on hover */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
  border: 1px solid #F2F2F2; /* Add a light grey border on hover */
  font-size: 13px; /* Slightly increase text size on hover */
  line-height: 1.2; /* Maintain the same line height */
  }
  
/* Mobile Styles */
@media (max-width: 768px) {
  .achievements-section {
      padding: 15px; /* Adjust padding for smaller screens */
      max-width: 100%; /* Full width for the section */
      padding: 10px;
  }

  .achievements-section h2 {
      font-size: 22px; /* Adjust heading size */
  }

  .achievements-section p {
      font-size: 18px; /* Adjust paragraph size */
  }

  .achievement-list li {
      flex-direction: row; /* Keep image and text on the same line */
      align-items: center; /* Align items to the center */
      text-align: start;
  }

  .achievement-list img {
      margin-bottom: 0; /* Remove margin below icon */
  }

  .achievement-list h3 {
      font-size: 16px; /* Adjust font size */
  }

  .achievement-list p {
      font-size: 14px; /* Adjust font size */
  }

  .imgstyle {
      padding: 5px 10px; /* Adjust padding for mobile */
  }
}
