.app-container {
    padding: 40px; /* Padding around the whole app */
    background-color: #e9f1fb;  /* Light background color */
    
  }
  
  .contact-section {
    display: flex; /* Flexbox for aligning form and info side by side */
    justify-content: center; /* Space between the two sections */
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .contact-section {
      flex-direction: column; /* Stack sections vertically on smaller screens */
    }
  }
  