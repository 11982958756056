.about-me-container {
    display: flex; /* Flexbox for aligning image and details side by side */
    padding: 40px; /* Padding around the section */
    background-color: #e9f1fb; /* Light blue background */
  }

  .aboutimg {


    padding: 10px 10px; /* Padding inside button */
  border: none; /* No border */
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  
  align-self: center;
  background-color: #F2F2F2; /* Light grey background on hover */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
  border: 1px solid #F2F2F2; /* Add a light grey border on hover */

  }
  
  .profile-image img {
    width: 250px; /* Fixed size for the profile picture */
    border-radius: 20px; /* Rounded corners */
  }
  
  .personal-details {
    margin-left: 30px; /* Space between image and details */
    color: #1f2a47; /* Darker color for text */
  }
  
  .personal-details h2 {
    font-size: 18px; /* Font size for About Me heading */
    color: #1e90ff; /* Bright blue for heading */
  }
  
  .personal-details h3 {
    font-size: 24px; /* Font size for name */
    margin: 10px 0; /* Space above and below name */
  }
  
  .occupation a {
    color: #0066ff; /* Blue color for clickable occupations */
    text-decoration: none; /* Remove underline */
  }
  
  .occupation a:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  .residence {
    margin: 20px 0; /* Space above and below residence details */
    font-size:14px;
  }
  
  .bio {
    margin-bottom: 20px; /* Space below bio */
    font-size: 14px; /* Font size for bio text */
    font-weight: 700;
  }
  
  .skills-tags span {
    background-color: #e0eaff; /* Light blue background for tags */
    padding: 5px 10px; /* Padding inside tags */
    margin: 5px; /* Margin around each tag */
    border-radius: 10px; /* Rounded corners */
    display: inline-block; /* Display tags inline */
    font-size: 14px; /* Font size for tags */
    color: #0066ff; /* Blue color for tags */
    border: none; /* No border */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
    align-self: center;
    background-color: #F2F2F2; /* Light grey background on hover */
    box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
    border: 1px solid #F2F2F2; /* Add a light grey border on hover */
  }
  
  .download-cv {
    margin-top: 10px;
    padding: 10px 20px; /* Padding inside the button */
    border: none; /* No border */
    border-radius: 10px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size for button text */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
    background-color: #000; /* Light grey background on hover */
    box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
    border: 1px solid #F2F2F2; /* Add a light grey border on hover */
  }
  
  .download-cv:hover {
    background-color: #e0eaff; /* Light blue background on hover */
    transform: scale(1.05); /* Slightly increase size on hover */
  }
  
  .download-icon {
    margin-left: 10px; /* Space between text and icon */
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .about-me-container {
      flex-direction: column; /* Stack image and details vertically on smaller screens */
      align-items: center; /* Center align the content */
      margin: 0% -15px;
    padding: 10px; /* Padding around the section */
    margin-bottom: 50px;
    margin-top: 30px;
    }

    .aboutimg {
      margin-top: 15px;
    }
  
    .profile-image img {
      width: 200px; /* Reduce size of profile picture on mobile */
    }
  
    .personal-details {
      margin-left: 0; /* Remove left margin on mobile */
      margin-top: 20px;
      text-align: center; /* Center text on mobile */
    }
  
    .skills-tags span {
      display: block; /* Stack tags vertically on mobile */
      margin: 5px auto; /* Center the tags */
    }

    .skills-tags span {
      background-color: #e0eaff; /* Light blue background for tags */
      padding: 5px 10px; /* Padding inside tags */
      margin: 5px; /* Margin around each tag */
      border-radius: 10px; /* Rounded corners */
      display: inline-block; /* Display tags inline */
      font-size: 14px; /* Font size for tags */
      color: #0066ff; /* Blue color for tags */
      border: none; /* No border */
      transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
      align-self: center;
      background-color: #F2F2F2; /* Light grey background on hover */
      box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
      border: 1px solid #F2F2F2; /* Add a light grey border on hover */
      width: fit-content;
      margin-bottom: 15px;
    }

    .download-cv {
      margin-top: 10px;
      padding: 10px 20px; /* Padding inside the button */
      border: none; /* No border */
      border-radius: 10px; /* Rounded corners */
      cursor: pointer; /* Pointer cursor on hover */
      font-size: 16px; /* Font size for button text */
      transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
      background-color: #F2F2F2; /* Light grey background on hover */
      box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
      border: 1px solid #F2F2F2; /* Add a light grey border on hover */
    }
  }
  