.app-container90 {
    display: flex; /* Flexbox to place Achievements and SkillsLogos side by side */
    justify-content: space-between; /* Space between the two sections */
    padding:0 10%; /* Padding around the whole app */
    background-color: #e9f1fb; /* Light background color */
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .app-container90 {
      flex-direction: column; /* Stack sections vertically on smaller screens */
      padding: 0 10%; /* Reduce padding on mobile */
    }
  }
  