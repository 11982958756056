.tags-container {
    display: flex; /* Flexbox for horizontal alignment */
    gap: 10px; /* Space between tags */
    justify-content: center; /* Center the tags */
    margin-bottom: 20px; /* Space below the tags */
  }

  .download-cv {
    margin-top: 10px;
    padding: 10px 20px; /* Padding inside the button */
    border: none; /* No border */
    border-radius: 10px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size for button text */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
    background-color: #F2F2F2; /* Light grey background on hover */
    box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
    border: 1px solid #F2F2F2; /* Add a light grey border on hover */
    color: #1e90ff;
    font-weight: 900;
  }
  
  .tag {
    padding: 10px 20px; /* Padding inside each tag */
    background-color: #e0eaff; /* Light blue background */
    border: none; /* Remove default border */
    border-radius: 20px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    color: #0066ff; /* Blue text color */
    transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
  }
  
  .tag:hover {
    background-color: #0066ff; /* Darker blue background on hover */
    color: #ffffff; /* White text on hover */
  }
  
  .tag.active {
    background-color: #0066ff; /* Darker blue for active tag */
    color: #ffffff; /* White text for active tag */
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .tags-container {
      flex-wrap: wrap; /* Wrap tags to the next line on smaller screens */
      gap: 5px; /* Reduce gap between tags on mobile */
    }
  
    .tag {
      padding: 8px 16px; /* Slightly smaller padding on mobile */
    }
  }
  