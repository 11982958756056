.navbar {
  background-color: #e9f1fb; /* Light blue background */
  padding: 20px; /* Padding around the navbar */
  position: relative; /* Position relative to contain absolute positioned elements */
  z-index: 9;
}

.nav-links {
  list-style-type: none; /* Remove bullets from the list */
  display: flex; /* Flexbox to align items */
  justify-content: center; /* Center the items horizontally */
  gap: 10px; /* Space between the links */
  flex-wrap: wrap; /* Allow links to wrap to the next line if needed */
  background-color: #e9f1fb;
}

.nav-links li {
  display: flex; /* Use Flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  cursor: pointer; /* Pointer cursor for links */
  font-weight: 500; /* Regular text weight */
  font-size: 12px; /* Font size */
  line-height: 1.2; /* Set line height to accommodate font size change */
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s, font-size 0.3s; /* Smooth transitions */
  padding: 8px 16px; /* Add padding for a button-like effect */
  border-radius: 8px; /* Rounded corners */
  min-height: 36px; /* Set minimum height to accommodate the hover state */
  min-width: 100px; /* Set minimum width to accommodate the hover state */
  box-sizing: border-box; /* Ensure padding and border are included in element's width and height */
}

.nav-links li:hover {
  color: #000000; /* Change text color to black on hover */
  background-color: #F2F2F2; /* Light grey background on hover */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.8), 0 8px 16px rgba(0, 0, 0, 0.2); /* White shadow at top and existing shadow */
  border: 1px solid #F2F2F2; /* Add a light grey border on hover */
  font-size: 13px; /* Slightly increase text size on hover */
  line-height: 1.2; /* Maintain the same line height */
}

/* Hamburger menu styles */
.hamburger-menu {
  display: none; /* Hidden by default */
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}


/* Mobile Styles */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column; /* Stack links vertically */
    align-items: center; /* Center align the stacked items */
    display: none; /* Hide links by default */
    position: absolute; /* Overlay the menu */
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #e9f1fb;
    padding: 20px 0;
  }

  .nav-links.mobile-menu-open {
    display: flex; /* Show links when the menu is open */
  }

  .nav-links li {
    margin-bottom: 10px; /* Add space between stacked links */
  }

  .hamburger-menu {
    display: block; /* Show hamburger menu on mobile */
    left: 20px;
  }
}
